$cacheSpinnerSize: 50px;
$cacheSpinnerLineSize: 40px;
$cacheLabelSize: 30px;

.cache-loader {
    text-align: center;
    margin: auto;

    .cache-loader-label {
        font-size: 2em;
    }

    ion-spinner {
        height: $cacheSpinnerSize;
        width: $cacheSpinnerSize;
        * {
            width: $cacheSpinnerSize;
            height: $cacheSpinnerSize;
            stroke: var(--ion-color-primary);
            stroke-width: 5px;
        }
    }

    &.line {
        display: flex;
        height: $cacheSpinnerLineSize;

        .cache-loader-label {
            height: $cacheSpinnerLineSize;
            line-height: $cacheSpinnerLineSize;
            font-size: 1.3em;
            flex: 1;
        }

        ion-spinner {
            height: $cacheSpinnerLineSize;
            width: $cacheSpinnerLineSize;
            * {
                width: $cacheSpinnerLineSize;
                height: $cacheSpinnerLineSize;
            }
        }
    }
}

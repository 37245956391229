// http://ionicframework.com/docs/v2/theming/


// App Shared Imports
// --------------------------------------------------
// These are the imports which make up the design of this app.
// By default each design mode includes these shared imports.
// App Shared Sass variables belong in app.variables.scss.

.app-menu-title {
    .toolbar-title {
        display: flex;
        align-items: center;

        .app-name {
            padding-left: 10px;
        }

        .app-version {
            position: absolute;
            top: 0;
            right: 10px;
            font-size: 0.6em;
            font-style: italic;
        }
    }
}

.toast-wrapper {
    background-color: rgba(50, 50, 50, 0.9);
}

ion-card {
    ion-card-header[primary] {
        color: var(--ion-color-primary);
    }

    .indicator {
        position: absolute;
        top: 0px;
        right: 0px;
        border-bottom-left-radius: 5px;
        padding: 2px 7px;
        text-transform: uppercase;
    }

    &.with-map {
        img {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 100%;
        }
    }
}

.element-info {
    margin: 7px 0px;

    .name {
        font-weight: 600;
    }

    .description {
        padding-left: 12px;
        font-size: small;
        font-style: italic;
        color: rgba(0, 0, 0, 0.65);
    }
}

[sagamenu] {
    display: inline-block !important;
}

ion-nav.secondary {
    [sagamenu] {
        display: none !important;
    }
}

ion-navbar.force-back-button {
    .back-button {
        display: inline-block;
    }
}

.fixed-bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px 25px;
}

ion-nav.no-menu {
    [sagamenu] {
        display: none !important;
    }
}

.saga-menu {
    .scroll-content {
        display: flex;
        flex-direction: column;

        .brand {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            left:0;
            opacity: 0.1;
            img {
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
            }
        }

        ion-list.menu-entries {
            flex: 1;
            overflow: auto;
            margin-bottom: 5px;
            position: relative;

            ion-item {
                background-color: transparent;
            }
        }

        ion-list.menu-bottom {
            margin:0;
        }
    }
}


button[fab-bottom] {
    position: fixed;
    bottom: 20px;
}

button[fab-right] {
    position: fixed;
    right: 20px;
}

.cordova-camera-capture {
    display: flex;
    background: black !important;

    .cordova-camera-capture-content {
        margin: auto;
    }

    > div+div {
        position: absolute;
        bottom: 20px;
        width: 100%;

        > div:first-child {
            text-align: right !important;
            width: calc(50% + 32px) !important;
        }

        > div:last-child {
            text-align: right !important;
            width: 24% !important;
        }

        .capture {
            height: 64px;
            width: 64px;
            border-radius: 32px;

            box-shadow: 0 0 0 4px white inset;
            background: transparent;
        }

        .cancel {
            height: 50px;
            width: 50px;
            border-radius: 25px;

            background-color: transparent;
            color: white;
            font-size: 35pt;
            font-family: cursive;
        }
    }
}

.multiselect ion-select {
    opacity: 1 !important;
}

ion-title {
  &.with-subtitle {
    h2 {
      margin-top: .25em;
      margin-bottom: .25em;
    }
    p {
      margin-top: .25em;
      margin-bottom: .25em;
    }
  }
}

:root {
  --permanent-menu-width: 50px;
  --permanent-menu-button-height: 50px;
  --permanent-menu-background-color: var(--ion-color-light);
  --drawing-tools-panel-height: 50px;
}

:root .dark {
  --permanent-menu-background-color: var(--ion-background-color);
}

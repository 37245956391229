[layout] {
    display: flex;

    &[layout=column] {
        flex-direction: column;
    }

    &[layout=row] {
        flex-direction: row;
    }
}

[flex], .flex {
    flex: 1;
}

@import 'app';
@import 'buttons';
@import 'cacheLoader';
@import 'chip';
@import 'ion-modal';
@import 'ion-title';
@import 'ion-popover';
@import 'large-font';
@import 'layout';
@import 'to-clean';

[scroll-no-padding] {
  .scroll-content {
    padding-bottom: 0 !important;
  }
}

.badged-label {
  width: 100%;
  display: flex;
  align-items: baseline;
  margin: 0;

  > :not(ion-badge) {
    flex: 1;
  }
}

.material-icons.large {
  font-size: 32px !important;
}

.alert-radio-group {
  button {
    contain: content !important;
    height: auto !important;

    .alert-radio-label {
      white-space: pre-line;
    }
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.select {
  -webkit-touch-callout: default; /* iOS Safari */
  -webkit-user-select: auto; /* Safari */
  -khtml-user-select: default; /* Konqueror HTML */
  -moz-user-select: auto; /* Firefox */
  -ms-user-select: auto; /* Internet Explorer/Edge */
  user-select: auto; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

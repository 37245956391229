[fab-button] {
    width: 80px;
    height: 80px;
    border-radius: 50%;

    &[fab-button-small] {
        width: 60px;
        height: 60px;
    }
}
